import { useNotifierAction } from "@lib/notification";
import staticImageLoader from "@lib/staticImageLoader";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { connectShopify, getIsLogin } from "@services/app";
import { getUrlParams } from "@utils/getUrlParams";
import { useInterval } from "ahooks";
import Image from "next/image";
import shopifyImage from "public/image/platforms/shopify.svg";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PlatformButton from "../platformButton";

const ShopifyLoginForm = (props) => {
  const { classes, app, successCallBack, setLoginMethod } = props;
  const [domain, setDomain] = useState("");
  const [loading, setLoading] = useState(false);
  const [interval, setInterval] = useState(null);

  const { enqueueSnackbar } = useNotifierAction();

  useInterval(
    () => {
      if (
        window.printy6ShipifyLoginWindow &&
        window.printy6ShipifyLoginWindow.closed
      ) {
        setInterval(null);
        setLoading(false);
      }
    },
    interval,
    { immediate: true }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("message", listernMessage, { passive: false });

    return function cleanup() {
      window.removeEventListener("message", listernMessage);
    };
  }, []);

  const listernMessage = (e) => {
    if (!e || !e.data || !(e.data.from === "oauth")) {
      return false;
    }

    if (!app.user) {
      const returnUrl = getUrlParams("return_url");

      getIsLogin(null, {})
        .then((res) => {
          let data = res.data;
          if (data.user) {
            successCallBack &&
              successCallBack(
                Object.assign({}, data, {
                  redirect: returnUrl || "/dashboard/home",
                })
              );
          } else {
            setLoading(false);

            enqueueSnackbar({
              message: "Fail to login with Shopify account.",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            });
          }
        })
        .catch((e) => {
          setLoading(false);

          enqueueSnackbar({
            message: "Something wrong, please try it again.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
            },
          });
        });
    } else {
    }
  };

  const login = (e) => {
    e.preventDefault();

    if (domain.trim().length < 2) {
      return;
    }

    let x = window.outerWidth / 2 - 250;
    window.printy6ShipifyLoginWindow = window.open(
      "/portal/loading",
      "",
      `location=no,width=500,height=700,left=${x}`
    );

    setInterval(500);

    setLoading(true);

    connectShopify({
      domain: domain.trim(),
    })
      .then((res) => {
        let data = res.data;
        window.printy6ShipifyLoginWindow.location.replace(
          data.redirect,
          "",
          `location=no,width=500,height=700,left=${x}`
        );
      })
      .catch((e) => {
        setLoading(false);
        window.printy6ShipifyLoginWindow.close();
      });
  };

  const goBack = () => {
    setLoginMethod("email");
  };

  return (
    <Box
      sx={{
        mt: 2,
        mb: 1,
      }}
    >
      <Box
        sx={{
          my: 1,
        }}
      >
        <Typography variant="body2">
          Enter your shop url to continue.
        </Typography>
        <Typography
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          variant="caption"
          color="textSecondary"
          onClick={goBack}
        >
          Use email, Go back.
        </Typography>
      </Box>
      <form
        action="api/v2/platform/shopify/connect"
        method="post"
        onSubmit={login}
      >
        <Box
          sx={{
            mt: 1.5,
            mb: 3,
          }}
        >
          <FormControl fullWidth>
            <TextField
              name="shopify_name"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              variant="outlined"
              size="small"
              type="text"
              placeholder="your-shop-url"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{
                      "& p": {
                        fontSize: 14,
                        fontWeight: 600,
                        color: (theme) => theme.palette.text.primary,
                      },
                    }}
                    position="end"
                  >
                    .myshopify.com
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Box>
        <div>
          <PlatformButton
            id="login-shopify-login"
            icon={
              <Image
                width={24}
                height={24}
                src={shopifyImage}
                alt="shopify"
                loader={staticImageLoader}
              />
            }
          >
            {loading ? (
              <CircularProgress
                size={20}
                sx={{
                  position: "absolute",
                  top: `clac(50% - 10px)`,
                  left: `clac(50% - 10px)`,
                }}
              />
            ) : (
              <Typography variant="subtitle2" display="inline">
                Continue with Shopify
              </Typography>
            )}
          </PlatformButton>
        </div>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  const { app } = state;
  return { app };
}

export default connect(mapStateToProps)(ShopifyLoginForm);
