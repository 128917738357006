import { Box, Link as MuiLink } from "@mui/material";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import Platform from "../platform";
import Policy from "./policy";
import RegisterForm from "./registerForm";

const RegisterWithEmail = (props) => {
  const { setLoginMethod, successCallBack, switchSignIn } = props;

  return (
    <Slide direction="right" in={true} timeout={200} mountOnEnter unmountOnExit>
      <div>
        <RegisterForm successCallBack={successCallBack} />
        <Box
          sx={{
            display: "flex",
            mt: 1.5,
          }}
        >
          <Link href="/forgot_password" as="/recover" passHref>
            <MuiLink href="/recover" variant="caption" color="textSecondary">
              Trouble signing in?
            </MuiLink>
          </Link>
          <Box
            sx={{
              ml: "auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="caption"
              color="textSecondary"
              align="center"
              display="block"
            >
              Already have an account?&nbsp;&nbsp;
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              align="center"
              display="block"
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                switchSignIn();
              }}
            >
              Sign In
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            my: 3,
            borderBottom: "1px solid",
            borderBottomColor: (theme) => theme.palette.divider,
            textAlign: "center",
            lineHeight: 0,
            "& span": {
              backgroundColor: "#ffffff",
              lineHeight: 0,
              verticalAlign: "bottom",
              p: 1.5,
              color: "#40354e",
            },
          }}
        >
          <span>OR</span>
        </Box>
        <Platform
          setLoginMethod={setLoginMethod}
          successCallBack={successCallBack}
        />
        <Policy />
      </div>
    </Slide>
  );
};

export default RegisterWithEmail;
