import { Box } from "@mui/material";
import Google from "./google";

const Platform = (props) => {
  const { classes, setLoginMethod, successCallBack } = props;

  return (
    <Box sx={{ my: 2 }}>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Google classes={classes} successCallBack={successCallBack} />
      </Box>
      {/* <Box
        sx={{
          mt: 2,
        }}
      >
        <Shopify classes={classes} setLoginMethod={setLoginMethod} />
      </Box> */}
    </Box>
  );
};

export default Platform;
