import staticImageLoader from "@lib/staticImageLoader";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import LogoImage from "public/image/logo.svg";
import React from "react";
import LoginWithEmail from "./loginWithEmail";
import LoginWithShopify from "./loginWithShopify";

type LoginMethod = "email" | "shopify";

const SignIn = (props) => {
  const { successCallBack, switchSignUp } = props;
  const [loginMethod, setLoginMethod] = React.useState<LoginMethod>("email");

  const loginMethodDOM = React.useMemo(() => {
    switch (loginMethod) {
      case "email":
        return (
          <LoginWithEmail
            setLoginMethod={setLoginMethod}
            successCallBack={successCallBack}
            switchSignUp={switchSignUp}
          />
        );
      case "shopify":
        return (
          <LoginWithShopify
            setLoginMethod={setLoginMethod}
            successCallBack={successCallBack}
          />
        );

      default:
        return <></>;
    }
  }, [loginMethod, setLoginMethod, successCallBack, switchSignUp]);

  return (
    <Box>
      <Box sx={{ textAlign: "center" }}>
        <Image
          src={LogoImage}
          alt="printy6 logo"
          width={156}
          height={48}
          loader={staticImageLoader}
        />
        <Typography variant="h6" align="center" component="h1" sx={{ mt: 3 }}>
          Sign in to Printy6
        </Typography>
      </Box>
      {loginMethodDOM}
    </Box>
  );
};

export default SignIn;
