import Slide from "@mui/material/Slide";
import Policy from "./policy";
import ShopifyLoginForm from "./shopifyLoginForm";

const LoginWithShopify = (props) => {
  const { setLoginMethod, successCallBack } = props;

  return (
    <Slide direction="left" in={true} timeout={200} mountOnEnter unmountOnExit>
      <div>
        <ShopifyLoginForm
          setLoginMethod={setLoginMethod}
          successCallBack={successCallBack}
        />
        <Policy />
      </div>
    </Slide>
  );
};

export default LoginWithShopify;
