import { Box } from "@mui/material";
import * as React from "react";

export default function PlatformButton({
  id,
  disabled,
  icon,
  children,
  onClick,
}: {
  id: string;
  disabled?: boolean;
  icon: React.ReactElement;
  children: React.ReactElement | React.ReactElement[];
  onClick?: () => void;
}) {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <Box
      component="button"
      disabled={disabled}
      onClick={handleClick}
      sx={{
        width: "100%",
        height: 44,
        outline: "none",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: 1,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#faf9f5",
        position: "relative",
        "&:hover": {
          background: "#f4f3f4",
          borderColor: "rgba(0, 0, 0, 0.4)",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: 24,
        }}
      >
        {icon}
      </Box>
      {children}
    </Box>
  );
}
