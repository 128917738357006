import { Box, Link as MuiLink, Slide } from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import Platform from "../platform";
import LoginForm from "./loginForm";
import Policy from "./policy";

const LoginWithEmail = (props) => {
  const { setLoginMethod, successCallBack, switchSignUp } = props;

  return (
    <Slide direction="right" in={true} timeout={200} mountOnEnter unmountOnExit>
      <div>
        <LoginForm successCallBack={successCallBack} />
        <Box sx={{ display: "flex", mt: 1.5 }}>
          <Link href="/forgot_password" as="/recover" passHref>
            <MuiLink href="/recover" color="textSecondary" variant="caption">
              Trouble signing in?
            </MuiLink>
          </Link>
          <Box
            sx={{
              cursor: "pointer",
              ml: "auto",
              textDecoration: "underline",
            }}
            onClick={() => {
              switchSignUp();
            }}
          >
            <Typography
              variant="caption"
              color="textSecondary"
              align="center"
              display="block"
            >
              Sign Up
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            my: 3,
            mx: 0,
            borderBottom: "1px solid",
            borderBottomColor: (theme) => theme.palette.divider,
            textAlign: "center",
            lineHeight: 0,
            "& span": {
              bgcolor: "#ffffff",
              lineHeight: 0,
              verticalAlign: "bottom",
              p: 2,
              color: (theme) => theme.palette.text.secondary,
            },
          }}
        >
          <span>OR</span>
        </Box>
        <Platform
          setLoginMethod={setLoginMethod}
          successCallBack={successCallBack}
        />
        <Policy />
      </div>
    </Slide>
  );
};

export default LoginWithEmail;
