import { Box, Link as MuiLink, Typography } from "@mui/material";

const Policy = (props) => {
  const termsOfService = "/page/terms-of-service/";
  const privacyPolicy = "/page/privacy-policy/";

  return (
    <Box sx={{ mt: 1 }}>
      <Typography variant="caption" display="block" color="textSecondary">
        By clicking Sign Up, Continue with Google, Continue with Shopify, you
        agree to Printy6's{" "}
        <MuiLink href={termsOfService} color="textSecondary">
          Terms of Service
        </MuiLink>{" "}
        and{" "}
        <MuiLink href={privacyPolicy} color="textSecondary">
          Privacy Policy
        </MuiLink>
        . Printy6 may send you communications; you may change your preferences
        in your account settings. We'll never post without your permission.
      </Typography>
    </Box>
  );
};

export default Policy;
