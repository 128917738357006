import { Box, Link } from "@mui/material";
import Typography from "@mui/material/Typography";

const Policy = (props) => {
  const { classes } = props;
  const termsOfService = "/page/terms-of-service/";
  const privacyPolicy = "/page/privacy-policy/";

  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="caption" display="block" color="textSecondary">
        By clicking Sign In, Continue with Google, Continue with Shopify, you
        agree to Printy6's{" "}
        <Link color="textSecondary" href={termsOfService}>
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link color="textSecondary" href={privacyPolicy}>
          Privacy Policy
        </Link>
        . Printy6 may send you communications; you may change your preferences
        in your account settings. We'll never post without your permission.
      </Typography>
    </Box>
  );
};

export default Policy;
