import staticImageLoader from "@lib/staticImageLoader";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import LogoImage from "public/image/logo.svg";
import { useState } from "react";
import LoginWithShopify from "./loginWithShopify";
import RegisterWithEmail from "./registerWithEmail";

const SignUp = (props) => {
  const { successCallBack, switchSignIn } = props;
  const [loginMethod, setLoginMethod] = useState("email");

  const getLoginMethod = () => {
    switch (loginMethod) {
      case "email":
        return (
          <RegisterWithEmail
            setLoginMethod={setLoginMethod}
            successCallBack={successCallBack}
            switchSignIn={switchSignIn}
          />
        );
        break;
      case "shopify":
        return (
          <LoginWithShopify
            setLoginMethod={setLoginMethod}
            successCallBack={successCallBack}
          />
        );
        break;

      default:
        break;
    }
  };

  return (
    <Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Image
          src={LogoImage}
          alt="printy6 logo"
          width={156}
          height={48}
          loader={staticImageLoader}
        />
        <Typography variant="h6" align="center" component="h1" sx={{ mt: 3 }}>
          Sign up to Printy6
        </Typography>
      </Box>
      {getLoginMethod()}
    </Box>
  );
};

export default SignUp;
