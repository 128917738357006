import staticImageLoader from "@lib/staticImageLoader";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { postGoogleLogin } from "@services/app";
import { getUrlParams } from "@utils/getUrlParams";
import Image from "next/image";
import GoogleImage from "public/image/platforms/google.svg";
import { useEffect, useState } from "react";
import PlatformButton from "../platformButton";

const Google = (props) => {
  const { successCallBack } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!window.gapi) {
      loadAndInitGAPI();
    } else {
      setLoading(false);
    }
  }, []);

  function loadAndInitGAPI() {
    return new Promise(() => {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://apis.google.com/js/platform.js";
      script.onload = () => {
        onGoogleApiLoaded();
      };
      document.getElementsByTagName("head")[0].appendChild(script);
    });
  }

  const onGoogleApiLoaded = (callback?: () => void) => {
    if (window.gapi && !window.gapi.auth2) {
      window.gapi.load("auth2", () => {
        callback && callback();
      });
      setLoading(false);
    }
  };

  const signIn = () => {
    if (window.gapi) {
      if (window.gapi.auth2) {
        handleSignIn();
      } else {
        onGoogleApiLoaded(handleSignIn);
      }
    }
  };

  const handleSignIn = () => {
    setLoading(true);
    window.gapi.auth2.authorize(
      {
        client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
        scope: "email profile openid",
        response_type: "id_token permission",
      },
      (response) => {
        if (response.error) {
          setLoading(false);
          return;
        }

        const returnUrl = getUrlParams("return_url") as string;

        postGoogleLogin(
          Object.assign(
            {
              token: response.id_token,
            },
            returnUrl && {
              return_url: decodeURIComponent(returnUrl),
            }
          )
        ).then((res) => {
          const data = res.data;
          successCallBack && successCallBack(data);
        });
      }
    );
  };

  return (
    <PlatformButton
      id="login-with-google"
      onClick={signIn}
      disabled={loading}
      icon={
        <Image
          width={16}
          height={16}
          src={GoogleImage}
          alt="google"
          loader={staticImageLoader}
        />
      }
    >
      {loading ? (
        <CircularProgress
          size={20}
          sx={{
            position: "absolute",
            top: `clac(50% - 10px)`,
            left: `clac(50% - 10px)`,
          }}
        />
      ) : (
        <Typography variant="subtitle2" display="inline">
          Continue with Google
        </Typography>
      )}
    </PlatformButton>
  );
};

export default Google;
