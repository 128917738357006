import VisibilityOffIcon from "@mui/icons-material/VisibilityOffRounded";
import VisibilityIcon from "@mui/icons-material/VisibilityRounded";
import Button from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { postRegister } from "@services/app";
import { getUrlParams } from "@utils/getUrlParams";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const RegisterForm = (props) => {
  const { successCallBack } = props;
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);

  const recaptchaRef = useRef(null);

  const register = (e) => {
    e.preventDefault();

    if (captchaError) {
      return;
    }

    let captcha = recaptchaRef.current.getValue();

    setLoading(true);

    const returnUrl = getUrlParams("return_url");

    postRegister(
      Object.assign(
        {
          email: email,
          password: password,
          captcha,
        },
        returnUrl && {
          return_url: returnUrl,
        }
      )
    )
      .then((res) => {
        const data = res.data;
        successCallBack && successCallBack(data);
      })
      .catch((e) => {
        let data = e.response;
        if (data && data.error && data.error.errors) {
          setErrors(data.error.errors);
        }
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        mt: 2,
        mb: 1,
      }}
    >
      <form action="api/v2/auth/register" method="post" onSubmit={register}>
        <Box
          sx={{
            mt: 1.5,
          }}
        >
          <Typography gutterBottom variant="body2">
            Email
          </Typography>
          <FormControl fullWidth>
            <TextField
              error={errors["email"]}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              size="small"
              type="email"
              onFocus={() => setErrors({})}
            />
          </FormControl>
          {errors["email"] &&
            errors["email"].map((error, index) => (
              <FormHelperText error key={index}>
                {error}
              </FormHelperText>
            ))}
        </Box>
        <Box
          sx={{
            mt: 1.5,
          }}
        >
          <Typography gutterBottom variant="body2">
            Password
          </Typography>
          <FormControl fullWidth>
            <TextField
              error={errors["password"]}
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              size="small"
              type={passwordShow ? "text" : "password"}
              onFocus={() => setErrors({})}
            />
            <IconButton
              color="primary"
              size="small"
              sx={{
                position: "absolute",
                right: "10px",
                top: "calc(50% - 13px)",
              }}
              onClick={() => setPasswordShow(!passwordShow)}
            >
              {passwordShow ? (
                <VisibilityOffIcon color="disabled" style={{ fontSize: 20 }} />
              ) : (
                <VisibilityIcon color="action" style={{ fontSize: 20 }} />
              )}
            </IconButton>
          </FormControl>
          {errors["password"] &&
            errors["password"].map((error, index) => (
              <FormHelperText error key={index}>
                {error}
              </FormHelperText>
            ))}
        </Box>
        <div>
          <ReCAPTCHA
            ref={recaptchaRef}
            theme="light"
            sitekey="6LeDAbIUAAAAAAdXY-keV2acCE5fvHt0AiFf6j6X"
            onErrored={() => setCaptchaError(true)}
          />
        </div>
        {captchaError && (
          <FormHelperText error>
            Cannot load Google Recaptcha Service, please connect a VPN if you
            are visiting Printy6 from China.
          </FormHelperText>
        )}
        {errors["captcha"] &&
          errors["captcha"].map((error, index) => (
            <FormHelperText error key={index}>
              {error}
            </FormHelperText>
          ))}
        <Button
          disableElevation
          variant="contained"
          aria-label="Next"
          color="primary"
          fullWidth
          size="large"
          loading={loading}
          type="submit"
          disableRipple
          id="signup-email-signup"
          sx={{
            mt: 3,
          }}
        >
          Sign Up
        </Button>
      </form>
    </Box>
  );
};

export default RegisterForm;
