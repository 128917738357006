import VisibilityOffIcon from "@mui/icons-material/VisibilityOffRounded";
import VisibilityIcon from "@mui/icons-material/VisibilityRounded";
import Button from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { postLogin } from "@services/app";
import { getUrlParams } from "@utils/getUrlParams";
import * as React from "react";

const LoginForm = (props) => {
  const { successCallBack } = props;
  const [email, setEmail] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordShow, setPasswordShow] = React.useState(false);

  const login = (e) => {
    e.preventDefault();
    setLoading(true);
    const returnUrl = getUrlParams("return_url");
    postLogin(
      Object.assign(
        {
          email: email,
          password: password,
        },
        returnUrl && {
          return_url: returnUrl,
        }
      )
    )
      .then((res) => {
        const data = res.data;
        successCallBack && successCallBack(data);
      })
      .catch((e) => {
        let data = e.response;

        setErrors(data.error.errors || {});

        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        mt: 2,
        mb: 1,
      }}
    >
      <form action="api/v2/auth/login" method="post" onSubmit={login}>
        <Box sx={{ mt: 1.5 }}>
          <Typography gutterBottom variant="body2">
            Email
          </Typography>
          <FormControl fullWidth>
            <TextField
              error={!!errors["email"]}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              size="small"
              type="email"
              onFocus={() => setErrors({})}
              autoComplete="username"
            />
          </FormControl>
          {errors["email"] &&
            errors["email"].map((error, index) => (
              <FormHelperText error key={index}>
                {error}
              </FormHelperText>
            ))}
        </Box>
        <Box sx={{ mt: 1.5 }}>
          <Typography gutterBottom variant="body2">
            Password
          </Typography>
          <FormControl fullWidth>
            <TextField
              error={!!errors["password"]}
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              size="small"
              type={passwordShow ? "text" : "password"}
              onFocus={() => setErrors({})}
              autoComplete="current-password"
            />
            <IconButton
              color="primary"
              size="small"
              sx={{
                position: "absolute",
                right: "10px",
                top: "calc(50% - 13px)",
              }}
              onClick={() => setPasswordShow(!passwordShow)}
            >
              {passwordShow ? (
                <VisibilityOffIcon color="disabled" style={{ fontSize: 20 }} />
              ) : (
                <VisibilityIcon color="action" style={{ fontSize: 20 }} />
              )}
            </IconButton>
          </FormControl>
          {errors["password"] &&
            errors["password"].map((error, index) => (
              <FormHelperText error key={index}>
                {error}
              </FormHelperText>
            ))}
        </Box>
        <Button
          disableElevation
          variant="contained"
          aria-label="Next"
          color="primary"
          fullWidth
          size="large"
          loading={loading}
          type="submit"
          disableRipple
          id="login-email-login"
          sx={{
            mt: 3,
          }}
        >
          Sign In
        </Button>
      </form>
    </Box>
  );
};

export default LoginForm;
