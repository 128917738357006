import { Box } from "@mui/material";
import * as React from "react";
import SignIn from "../components/signIn";
import SignUp from "../components/signUp";

type Props = {
  initType: "signin" | "signup";
  successCallBack: (data: { redirect: string }) => void;
};

const App = (props: Props) => {
  const { initType = "signup", successCallBack } = props;
  const [type, setType] = React.useState<Props["initType"]>(initType);

  const content = React.useMemo(() => {
    switch (type) {
      case "signin": {
        return (
          <SignIn
            successCallBack={successCallBack}
            switchSignUp={() => setType("signup")}
          />
        );
      }
      case "signup": {
        return (
          <SignUp
            successCallBack={successCallBack}
            switchSignIn={() => setType("signin")}
          />
        );
      }
      default: {
        return (
          <SignUp
            successCallBack={successCallBack}
            switchSignIn={() => setType("signin")}
          />
        );
      }
    }
  }, [type, successCallBack]);
  // const getContent = () => {

  // };

  return (
    <Box
      sx={{
        p: 2,
        minHeight: 520,
        overflow: "hidden",
      }}
    >
      {content}
    </Box>
  );
};

export default App;
